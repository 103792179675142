
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { IServer } from "@/models/server/IServer";
import ApiService from "@/core/services/ApiService";
import { ElForm } from "element-plus";

export default defineComponent({
  name: "add-server-modal",
  emits: ["addedNewServer"],
  components: {},
  data() {
    return {
      addServerModalRef: "addServerModalRef",
      formRef: "addServer",
      formData: ref<IServer>({
        server_id: 0,
        name: "",
        address: "",
        port: 0,
        stage: 0,
        username: "",
        password: "",
      }),
      loading: ref<boolean>(false),
      rules: {
        name: [
          {
            required: true,
            message: "Server name is required",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "Server address is required",
            trigger: "change",
          },
        ],
        port: [
          {
            required: true,
            min: 1,
            message: "Server port is required",
            trigger: "change",
          },
        ],
        stage: [
          {
            required: true,
            min: 1,
            message: "Stage is required",
            trigger: "change",
          },
        ],
        username: [
          {
            required: true,
            message: "Server username is required",
            trigger: "change",
          },
        ],
        password: [
          {
            required: false,
            message: "Server password is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    addNewServer: function (newServer: IServer) {
      ApiService.vueInstance.axios
        .post("database/v1/server/add", newServer)
        .then(() => {
          hideModal(this.$refs[this.addServerModalRef] as HTMLElement);
          this.$emit("addedNewServer");
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
      this.loading = false;
    },
    submit() {
      if (!this.$refs[this.formRef]) {
        return;
      }

      (this.$refs[this.formRef] as typeof ElForm).validate((valid) => {
        if (valid) {
          this.loading = true;
          const input = {
            name: this.formData.name,
            address: this.formData.address,
            port: Number(this.formData.port),
            stage: Number(this.formData.stage),
            username: this.formData.username,
            password: this.formData.password,
          };
          this.addNewServer(input);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    },
    resetData() {
      (this.$refs[this.formRef] as typeof ElForm).resetFields();
    },
  },
});
