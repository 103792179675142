
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddServerModal from "@/components/modals/forms/AddServerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { IServer } from "@/models/server/IServer";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessage } from "element-plus";
import moment from "moment";

interface IExpiredAccess {
  server: {
    server_id: number;
    name: string;
    address: string;
    port: string;
    username: string;
    test_at: number;
  };
  member: {
    member_id: number;
    member_type: {
      id: number;
      name: string;
    };
    email: string;
    name: string;
    username: string;
  };
  access_grant: string;
  wildcard: string;
  created_at: number;
  updated_at: number;
}

export default defineComponent({
  name: "server-listing",
  emits: ["serversDeleted", "accessRevoked"],
  components: {
    Datatable,
    AddServerModal,
  },
  data() {
    return {
      checkedServers: [],
      headers: [
        {
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Address",
          key: "address",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
      ],
      expiredHeader: [
        {
          name: "No.",
          key: "id",
          sortable: true,
        },
        {
          name: "Username",
          key: "username",
          sortable: true,
        },
        {
          name: "Server",
          key: "server",
          sortable: true,
        },
        {
          name: "Access Grant",
          key: "access_grant",
          sortable: false,
        },
        {
          name: "Created At",
          key: "created_at",
          sortable: true,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      search: "",
      searchExpired: "",
      servers: ref<Array<IServer>>([]),
      initialData: ref<Array<IServer>>([]),
      expiredAccessList: ref<Array<IExpiredAccess>>([]),
      initialExpiredData: ref<Array<IExpiredAccess>>([]),
      isLoadingRevoke: ref<number | null>(null),
      checkedExpiredAccess: [],

      serverGroup: ref<number>(0),
    };
  },
  methods: {
    deleteSelectedServers() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.checkedServers.forEach((server) => {
            this.deleteServer(server);
          });
          this.checkedServers.length = 0;
          this.$emit("serversDeleted");
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },
    deleteServer(id) {
      for (let i = 0; i < this.servers.length; i++) {
        if (this.servers[i].server_id === id) {
          ApiService.vueInstance.axios
            .post("database/v1/server/" + id + "/delete")
            .catch((err) => {
              Swal.fire({
                title: err.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
          this.servers.splice(i, 1);
        }
      }
    },
    getServerList(stage: number | undefined) {
      let urlEndpoint = `database/v1/server/list?stage=`;
      if (stage) urlEndpoint += stage;
      ApiService.vueInstance.axios.get(urlEndpoint).then((response) => {
        this.servers.splice(0, this.servers.length, ...response.data.data);
        this.initialData.splice(0, this.servers.length, ...this.servers);
      });
    },
    keyMatches(obj, value): boolean {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    searchServer() {
      this.servers.splice(0, this.servers.length, ...this.initialData);
      if (this.search !== "") {
        const results = this.servers.filter((server) => {
          return this.keyMatches(server, this.search);
        });
        this.servers.splice(0, this.servers.length, ...results);
      }
    },
    async getExpiredAccess() {
      try {
        const expiredAccessList = await ApiService.vueInstance.axios.get(
          "database/v1/access/list/expired"
        );
        const accesList = expiredAccessList?.data?.data?.map((el, idx) => {
          return { id: idx, ...el };
        });
        this.expiredAccessList.splice(
          0,
          this.expiredAccessList.length,
          ...accesList
        );
        this.initialExpiredData.splice(
          0,
          this.expiredAccessList.length,
          ...accesList
        );
      } catch (error) {
        //Perform errror action
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    searchExpiredAccess() {
      this.expiredAccessList.splice(
        0,
        this.expiredAccessList.length,
        ...this.initialExpiredData
      );
      if (this.searchExpired !== "") {
        const results = this.expiredAccessList.filter((el) => {
          return (
            el.server.name.includes(this.searchExpired) ||
            el.member.username.includes(this.searchExpired)
          );
        });
        this.expiredAccessList.splice(
          0,
          this.expiredAccessList.length,
          ...results
        );
      }
    },
    async revokeAccess(selectedExpiredAccess) {
      this.isLoadingRevoke = selectedExpiredAccess.id;
      const serverId = selectedExpiredAccess.server.server_id;
      try {
        await ApiService.vueInstance.axios.post(
          `database/v1/server/${serverId}/remove`,
          {
            member_id: [selectedExpiredAccess.member.member_id],
          }
        );

        setTimeout(() => {
          this.getServerList(undefined);
          this.getExpiredAccess();
          ElMessage.success(
            `Successfully revoke member: ${selectedExpiredAccess.member.email}`
          );
          this.$emit("accessRevoked");
          this.isLoadingRevoke = null;
        }, 2000);
      } catch (error) {
        if (error instanceof Error) {
          // ✅ TypeScript knows err is Error
          ElMessage.error(error.message);
        } else {
          ElMessage.error("Unexpected error");
        }
      }
    },
    unixTime(unixDate) {
      const normalDateFromUnix = new Date(unixDate * 1000);
      const date = new Date(normalDateFromUnix);
      return moment(date).isValid() ? moment(date).format("DD MMM YYYY") : "-";
    },
  },
  watch: {
    async serverGroup(newItem) {
      this.getServerList(newItem);
    },
  },
  mounted() {
    this.getServerList(undefined);
    this.getExpiredAccess();
    setCurrentPageBreadcrumbs("Server List", []);
  },
});
